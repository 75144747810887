// LoadingIndicator.js
import React from "react";
import Lottie from "lottie-react";
import mapLoadingAnimation from "./map-loading.json"; // Update the path accordingly
import "./GoogleMapsLoadingIndicator.css";

function LoadingIndicator() {
  return (
    <div className="loading-indicator">
      <Lottie
        animationData={mapLoadingAnimation}
        loop={true}
        className="lottie-animation"
      />
      <div className="loading-text">Loading Map...</div>
    </div>
  );
}

export default LoadingIndicator;
