import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Box,
  Typography,
  CircularProgress,
  Pagination,
  Paper,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function PodHistory() {
  const [podData, setPodData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const storageUserId = sessionStorage.getItem("storageUserId");
    if (storageUserId) {
      setUserId(storageUserId);
      console.log("User ID:", storageUserId);
    }
  }, []);

  useEffect(() => {
    const fetchPodData = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `/api/fetch-pod?userId=${encodeURIComponent(userId)}`,
          {
            method: "GET",
            headers: { "Content-Type": "application/json" },
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setPodData(data.result);
      } catch (error) {
        console.error("Failed to fetch POD data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchPodData();
    }
  }, [userId]);

  const handleEditPod = (podData) => {
    navigate("/edit-pod", { state: { podData } });
  };

  const handleDeletePod = async (orderNumber) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete Order Number: ${orderNumber}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-pod`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ orderNumber, userId }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Order number deleted successfully!");
      setPodData(podData.filter((item) => item.OrderNumber !== orderNumber));
    } catch (error) {
      console.error("Failed to delete Order number:", error);
      alert(`Error deleting Order number: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const filteredItems = podData.filter((item) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      item.CompanyName?.toLowerCase().includes(searchLower) ||
      item.Email?.toLowerCase().includes(searchLower) ||
      item.EmailStatus?.toLowerCase().includes(searchLower) ||
      item.Paid?.toString().toLowerCase().includes(searchLower) ||
      item.Date?.toLowerCase().includes(searchLower) ||
      item.OrderNumber?.toLowerCase().includes(searchLower)
    );
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
      <Typography variant="h4" component="div" sx={{ m: 2 }}>
        POD History
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <TextField
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by any column"
          margin="normal"
          sx={{ m: 2, width: "50%" }}
        />
      </Box>

      {isLoading ? (
        <CircularProgress
          sx={{ display: "flex", justifyContent: "center", m: 2 }}
        />
      ) : (
        <>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="pod table">
              <TableHead>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Email Status</TableCell>
                  <TableCell>Paid</TableCell>
                  <TableCell>Date</TableCell>
                  {/* <TableCell>PDF</TableCell> */}
                  <TableCell>Order Number</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentItems.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.CompanyName}</TableCell>
                    <TableCell>{item.Email}</TableCell>
                    <TableCell>{item.EmailStatus}</TableCell>
                    <TableCell>{item.Paid}</TableCell>
                    <TableCell>{item.Date}</TableCell>
                    <TableCell>{item.OrderNumber}</TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleEditPod(item)}>
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeletePod(item.OrderNumber)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            count={Math.ceil(filteredItems.length / itemsPerPage)}
            page={currentPage}
            onChange={(event, value) => setCurrentPage(value)}
            color="primary"
            sx={{ display: "flex", justifyContent: "center", m: 2 }}
            showFirstButton
            showLastButton
          />
        </>
      )}
    </Card>
  );
}

export default PodHistory;