import React from "react";
import "./LoadingIndicator.css";

function LoadingIndicator() {
  return (
    <div
      className="loading-container"
      role="status"
      aria-live="polite"
      aria-label="Loading"
    >
      <div className="dot dot1"></div>
      <div className="dot dot2"></div>
      <div className="dot dot3"></div>
      <div className="dot dot4"></div> {/* Added fourth dot */}
      <span className="sr-only">Loading...</span>
    </div>
  );
}

export default LoadingIndicator;
