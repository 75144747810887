import React, { useState, useEffect, useRef } from "react";
import "./Chat.css";
import MapMessage from "./MapMessage";
import LoadingIndicator from "./LoadingIndicator";
import ConfirmationModal from "./ConfirmationModal";
import { MdReplay } from "react-icons/md";
import { BsCheckLg } from "react-icons/bs";
import { jsPDF } from "jspdf";

function App() {
  const MAX_MESSAGES_PER_OPTION = 30;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOption, setModalOption] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showRequestCompletedOption, setShowRequestCompletedOption] =
    useState(false);
  const [showGeneratePdfButton, setShowGeneratePdfButton] = useState(false);

  const initialChatHistories = {
    Customs: [],
    Invoicing: [],
    "Email Generation": [],
    "Load Quote": [],
    "Document Generation": [],
  };

  const [chatHistories, setChatHistories] = useState(() => {
    const storedData = localStorage.getItem("chat_app_data");
    if (storedData) {
      const parsedData = JSON.parse(storedData).chat_histories || {};
      return { ...initialChatHistories, ...parsedData };
    } else {
      return initialChatHistories;
    }
  });

  const handleGeneratePdf = () => {
    const messages = chatHistories[selectedOption];

    const lastSystemMessage = [...messages]
      .reverse()
      .find((msg) => !msg.isUser && !msg.isMap);

    if (!lastSystemMessage) {
      alert("No system message found to generate PDF.");
      return;
    }

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const marginLeft = 50;
    const marginRight = 50;
    const marginTop = 50;
    const marginBottom = 50;
    const usableWidth = pageWidth - marginLeft - marginRight;

    let yPosition = marginTop;

    doc.setFont("Times");
    doc.setFontSize(14);

    const lines = lastSystemMessage.text.split("\n");

    lines.forEach((line, index) => {
      // Trim whitespace
      line = line.trim();

      if (line.startsWith("---")) {
        doc.setLineWidth(1);
        doc.line(marginLeft, yPosition, pageWidth - marginRight, yPosition);
        yPosition += 20;
      } else if (index <= 5) {
        doc.setFont("Times", "bold");
        doc.text(line, pageWidth / 2, yPosition, null, null, "center");
        yPosition += 20; // Line spacing
      } else if (line === "Signature") {
        doc.setFont("Times", "italic");
        doc.setFontSize(16);
        doc.text("Preet Dhillon", marginLeft, yPosition);
        yPosition += 24;

        doc.setFont("Times", "normal");
        doc.setFontSize(12);
      } else {
        doc.setFont("Times", "normal");

        if (line === "") {
          yPosition += 10; // Add a blank line
        } else {
          // Split long lines to fit within the usable width
          const textLines = doc.splitTextToSize(line, usableWidth);
          doc.text(textLines, marginLeft, yPosition);
          yPosition += textLines.length * 14; // Adjust line height as needed
        }
      }

      if (yPosition > pageHeight - marginBottom) {
        doc.addPage();
        yPosition = marginTop;
      }
    });

    doc.save("authorizationLetter.pdf");
  };

  const [input, setInput] = useState("");
  const chatBodyRef = useRef(null);
  const inputRef = useRef(null);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowGeneratePdfButton(false);
    if (!chatHistories[option]) {
      setChatHistories((prevHistories) => ({
        ...prevHistories,
        [option]: [],
      }));
    }

    if ((chatHistories[option]?.length || 0) === 0) {
      addSystemMessage(`How can I assist you with ${option}?`, option);
    }
  };

  const addSystemMessage = (text, option = selectedOption) => {
    const newMessage = {
      id: generateUniqueId(),
      text,
      isUser: false,
    };
    setChatHistories((prevHistories) => {
      const updatedOptionMessages = [
        ...prevHistories[option],
        newMessage,
      ].slice(-MAX_MESSAGES_PER_OPTION);
      return {
        ...prevHistories,
        [option]: updatedOptionMessages,
      };
    });
  };

  const openModal = (option) => {
    setModalOption(option);
    setIsModalOpen(true);
    setShowGeneratePdfButton(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalOption(null);
  };

  const apiEndpoints = {
    Customs: "/api/customs",
    Invoicing: "/api/invoicing",
    "Email Generation": "/api/email-generation",
    "Load Quote": "/api/load-quote",
    "Document Generation": "/api/document-generation",
  };

  useEffect(() => {
    let sessionId = sessionStorage.getItem("session_id");
    if (!sessionId) {
      sessionId = generateSessionId();
      sessionStorage.setItem("session_id", sessionId);
    }
  }, []);

  useEffect(() => {
    const storedOption = sessionStorage.getItem("selectedOption");
    if (storedOption) {
      setSelectedOption(storedOption);
    }
  }, []);

  useEffect(() => {
    if (selectedOption) {
      sessionStorage.setItem("selectedOption", selectedOption);
    } else {
      sessionStorage.removeItem("selectedOption");
    }
  }, [selectedOption]);

  useEffect(() => {
    const dataToStore = {
      chat_histories: chatHistories,
    };
    localStorage.setItem("chat_app_data", JSON.stringify(dataToStore));
  }, [chatHistories]);

  useEffect(() => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [chatHistories, isLoading]);

  function generateSessionId() {
    return crypto.randomUUID();
  }

  function generateUniqueId() {
    return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  }

  const handleSend = async () => {
    const textToSend = input.trim();
    if (!selectedOption) {
      alert("Please select an option before sending messages.");
      return;
    }
    if (textToSend === "") return;
    setShowRequestCompletedOption(false);

    const userMessage = {
      id: generateUniqueId(),
      text: textToSend,
      isUser: true,
    };

    setChatHistories((prevHistories) => {
      const updatedMessages = [
        ...prevHistories[selectedOption],
        userMessage,
      ].slice(-MAX_MESSAGES_PER_OPTION);
      return {
        ...prevHistories,
        [selectedOption]: updatedMessages,
      };
    });

    setInput("");
    setIsLoading(true);

    if (selectedOption === "Load Quote") {
      const response = await fetch("/api/find-pickup-delivery", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: textToSend,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch locations from the backend.");
      }

      const data = await response.json();
      const origin = data.message.pickup_location;
      const destination = data.message.delivery_location;

      let googleMapsEmbedUrl = `https://www.google.com/maps/embed/v1/directions?key=AIzaSyArBf33-aVC4NwUZEQJALQZPgmZbbC1_wM&origin=${encodeURIComponent(
        origin
      )}&destination=${encodeURIComponent(destination)}`;

      const mapsMessage = {
        id: generateUniqueId(),
        mapUrl: googleMapsEmbedUrl,
        isUser: false,
        isMap: true,
      };

      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          mapsMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    }

    try {
      const response = await callAzureFunction(textToSend, selectedOption);
      const answer = response.message;

      const aiMessage = {
        id: generateUniqueId(),
        text: answer,
        isUser: false,
      };

      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          aiMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });

      if (selectedOption === "Document Generation") {
        setShowGeneratePdfButton(true);
      }

      if (selectedOption !== "Load Quote") {
        setShowRequestCompletedOption(true);
      }
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          errorMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };

  const callAzureFunction = async (inputText, option) => {
    const apiUrl = apiEndpoints[option] || "/api/communications-manager";

    const response = await fetch(apiUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        question: inputText,
        session_id: sessionStorage.getItem("session_id"),
      }),
    });

    if (!response.ok) {
      throw new Error("API request failed");
    }

    return await response.json();
  };

  function getIconForOption(option) {
    const icons = {
      Customs: "📄",
      Invoicing: "💰",
      "Email Generation": "✉️",
      "Load Quote": "🚚",
      "Document Generation": "📝",
    };
    return icons[option] || "❓";
  }

  const adjustInputHeight = () => {
    if (inputRef.current) {
      inputRef.current.style.height = "auto";
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        150
      )}px`;
    }
  };

  useEffect(() => {
    adjustInputHeight();
  }, [input]);

  const confirmNewChat = async () => {
    if (modalOption) {
      await handleRequestCompleted(false);

      setChatHistories((prevHistories) => ({
        ...prevHistories,
        [modalOption]: [],
      }));

      if (selectedOption === modalOption) {
        addSystemMessage(
          `How can I assist you with ${modalOption}?`,
          modalOption
        );
      }
      closeModal();
    }
  };

  const handleRequestCompleted = async (displayMessage = true) => {
    const textToSend = "This part is completed";

    setShowRequestCompletedOption(false);

    setIsLoading(true);

    if (displayMessage) {
      const userMessage = {
        id: generateUniqueId(),
        text: textToSend,
        isUser: true,
      };

      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          userMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    }

    try {
      const response = await callAzureFunction(textToSend, selectedOption);
      const answer = response.message;

      const aiMessage = {
        id: generateUniqueId(),
        text: answer,
        isUser: false,
      };

      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          aiMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    } catch (err) {
      console.error("Error fetching response from the API:", err);
      const errorMessage = {
        id: generateUniqueId(),
        text: "Error fetching response from the system.",
        isUser: false,
      };
      setChatHistories((prevHistories) => {
        const updatedMessages = [
          ...prevHistories[selectedOption],
          errorMessage,
        ].slice(-MAX_MESSAGES_PER_OPTION);
        return {
          ...prevHistories,
          [selectedOption]: updatedMessages,
        };
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="app">
      <ConfirmationModal
        open={isModalOpen}
        handleClose={closeModal}
        handleConfirm={confirmNewChat}
        option={modalOption}
      />
      {!selectedOption && (
        <div className="welcome-screen">
          <h1 className="welcome-heading">What can I help with?</h1>
          <div className="options-container">
            {Object.keys(apiEndpoints).map((option) => (
              <button
                key={option}
                className="welcome-option-btn"
                onClick={() => handleOptionSelect(option)}
              >
                <span className="button-icon">{getIconForOption(option)} </span>
                {option}
              </button>
            ))}
          </div>
        </div>
      )}

      {selectedOption && (
        <div className="chat">
          <div className="chat-options">
            <header className="chat-header">
              {Object.keys(apiEndpoints).map((option) => (
                <div key={option} className="option-container">
                  <button
                    className={`option-chat-btn ${
                      selectedOption === option ? "active" : ""
                    }`}
                    onClick={() => handleOptionSelect(option)}
                  >
                    <span className="button-icon">
                      {getIconForOption(option)}{" "}
                    </span>
                    {option}
                  </button>
                  {selectedOption === option && (
                    <button
                      className="new-chat-btn"
                      onClick={() => openModal(option)}
                      title={`Start a new chat in ${option}`}
                      aria-label={`Start a new chat in ${option}`}
                    >
                      <MdReplay />
                    </button>
                  )}
                </div>
              ))}
            </header>
          </div>
          <div className="chat-window">
            <div className="chat-body" ref={chatBodyRef}>
              {(chatHistories[selectedOption] || []).map((message) => (
                <div
                  key={message.id}
                  className={`chat-message ${
                    message.isUser ? "user-message" : "system-message"
                  }`}
                >
                  {message.isMap ? (
                    <MapMessage mapUrl={message.mapUrl} />
                  ) : (
                    message.text
                  )}
                </div>
              ))}
              {isLoading && <LoadingIndicator />}
              {showRequestCompletedOption &&
                selectedOption !== "Document Generation" && (
                  <div className="chat-message request-completed-option">
                    <span style={{ marginRight: "10px" }}>
                      Task Completed ?
                    </span>
                    <button
                      className="complete-button"
                      onClick={handleRequestCompleted}
                    >
                      <BsCheckLg />{" "}
                    </button>
                  </div>
                )}
              {showGeneratePdfButton &&
                selectedOption === "Document Generation" && (
                  <div className="chat-message request-completed-option">
                    <span style={{ marginRight: "10px" }}>Generate PDF</span>
                    <button
                      className="complete-button"
                      onClick={handleGeneratePdf}
                    >
                      <BsCheckLg />{" "}
                    </button>
                  </div>
                )}
            </div>
            <div className="chat-footer">
              <button className="file-upload-btn" title="Upload Files">
                📌
              </button>

              <textarea
                ref={inputRef}
                value={input}
                onChange={(e) => {
                  setInput(e.target.value);
                  adjustInputHeight();
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" && !e.shiftKey && handleSend()
                }
                placeholder="Type a message..."
                className="input-field"
              />
              <button
                className="send-btn"
                title="Send Message"
                onClick={handleSend}
                aria-label="Send Message"
                disabled={isLoading}
              >
                ➤
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
