import React, { useState } from "react";
import LoadingIndicator from "./GoogleMapsLoadingIndicator.js";

function MapMessage({ mapUrl }) {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <div className="map-message">
      {isLoading && <LoadingIndicator />}
      <iframe
        title="Google Maps Directions"
        src={mapUrl}
        width="100%"
        height="500"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        onLoad={() => setIsLoading(false)}
      ></iframe>
    </div>
  );
}

export default MapMessage;