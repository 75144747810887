import React, { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Card,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Pagination,
  Paper,
  Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import Spinner from "./Spinner"; // Make sure this points to your Spinner component

function BrokersList() {
  const [brokers, setBrokers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newBroker, setNewBroker] = useState({
    brokerName: "",
    papsEmail: "",
    parsEmail: "",
    brokerPhone: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editedBrokers, setEditedBrokers] = useState([]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    fetchBrokers();
  }, []);

  const fetchBrokers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch("/api/fetch-brokers", {
        headers: { "Content-Type": "application/json" },
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setBrokers(data.result);
    } catch (error) {
      console.error("Failed to fetch brokers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const visibleBrokers = brokers.filter((broker) =>
    broker.brokerName.toLowerCase().includes(searchTerm)
  );

  const handleAddBroker = async (event) => {
    closeModal();
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch("/api/add-broker", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newBroker),
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      await fetchBrokers();
      setNewBroker({
        brokerName: "",
        papsEmail: "",
        parsEmail: "",
        brokerPhone: "",
      });
      alert("Broker added successfully!");
    } catch (error) {
      console.error("Error adding broker:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditChange = (e, brokerName) => {
    const { name, value } = e.target;
    setBrokers(
      brokers.map((broker) =>
        broker.brokerName === brokerName ? { ...broker, [name]: value } : broker
      )
    );
    if (!editedBrokers.includes(brokerName)) {
      setEditedBrokers([...editedBrokers, brokerName]);
    }
  };

  const saveChanges = async (broker) => {
    setIsLoading(true);
    try {
      const response = await fetch(`/api/update-broker`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          BrokerName: broker.brokerName,
          ParsEmail: broker.parsEmail,
          PapsEmail: broker.papsEmail,
          BrokerPhone: broker.brokerPhone,
        }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Changes saved successfully!");
      const newEditedBrokers = editedBrokers.filter(
        (name) => name !== broker.brokerName
      );
      setEditedBrokers(newEditedBrokers);
    } catch (error) {
      console.error("Error saving changes:", error.message);
      alert(`Error saving changes: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteBroker = async (brokerName) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete ${brokerName}?`
    );
    if (!confirmDelete) return;

    setIsLoading(true);
    try {
      const response = await fetch(`/api/delete-broker`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ brokerName }),
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Broker deleted successfully!");
      // Filter out the deleted broker from the brokers list
      setBrokers(brokers.filter((broker) => broker.brokerName !== brokerName));
    } catch (error) {
      console.error("Error deleting broker:", error);
      alert(`Error deleting broker: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const renderEditableCell = (value, broker, fieldName) => {
    const isEditing = editedBrokers.includes(broker.brokerName);

    return isEditing ? (
      <TextField
        type={
          fieldName.includes("Email")
            ? "email"
            : fieldName === "brokerPhone"
            ? "tel"
            : "text"
        }
        name={fieldName}
        value={value}
        onChange={(e) => handleEditChange(e, broker.brokerName)}
        autoFocus
        fullWidth
        InputProps={{
          endAdornment: (
            <IconButton onClick={() => saveChanges(broker)}>
              <CheckIcon color="success" />
            </IconButton>
          ),
        }}
      />
    ) : (
      value
    );
  };

  return (
    <Card sx={{ maxWidth: "100%", overflowX: "auto", m: 2 }}>
      <Typography variant="h4" component="div" sx={{ m: 2 }}>
        Broker Management
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
        <TextField
          placeholder="Search by Broker Name"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearch}
          sx={{ width: "50%" }}
        />
        <Button
          onClick={() => openModal()}
          variant="contained"
          sx={{ mx: 2, backgroundColor: "#3c94fc" }}
        >
          Add Broker
        </Button>
      </Box>
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer component={Paper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Broker Name</TableCell>
                <TableCell>PAPS Email</TableCell>
                <TableCell>PARS Email</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Action</TableCell>{" "}
              </TableRow>
            </TableHead>

            <TableBody>
              {visibleBrokers
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((broker) => (
                  <TableRow key={broker.brokerName}>
                    <TableCell>{broker.brokerName}</TableCell>
                    <TableCell>
                      {renderEditableCell(
                        broker.papsEmail,
                        broker,
                        "papsEmail"
                      )}
                    </TableCell>
                    <TableCell>
                      {renderEditableCell(
                        broker.parsEmail,
                        broker,
                        "parsEmail"
                      )}
                    </TableCell>
                    <TableCell>
                      {renderEditableCell(
                        broker.brokerPhone,
                        broker,
                        "brokerPhone"
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => {
                          if (!editedBrokers.includes(broker.brokerName)) {
                            setEditedBrokers([
                              ...editedBrokers,
                              broker.brokerName,
                            ]);
                          }
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => handleDeleteBroker(broker.brokerName)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            count={Math.ceil(visibleBrokers.length / itemsPerPage)}
            page={currentPage}
            onChange={(_, page) => setCurrentPage(page)}
            sx={{ py: 2, display: "flex", justifyContent: "center" }}
          />
        </TableContainer>
      )}
      <Dialog open={isModalOpen} onClose={closeModal} fullWidth>
        <DialogTitle>Add New Broker</DialogTitle>
        <DialogContent>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <TextField
                label="Broker Name"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={newBroker.brokerName}
                onChange={(e) =>
                  setNewBroker({ ...newBroker, brokerName: e.target.value })
                }
                margin="dense"
              />
              <TextField
                label="PAPS Email"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={newBroker.papsEmail}
                onChange={(e) =>
                  setNewBroker({ ...newBroker, papsEmail: e.target.value })
                }
                margin="dense"
              />
              <TextField
                label="PARS Email"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={newBroker.parsEmail}
                onChange={(e) =>
                  setNewBroker({ ...newBroker, parsEmail: e.target.value })
                }
                margin="dense"
              />
              <TextField
                label="Phone Number"
                fullWidth
                variant="standard"
                InputLabelProps={{ shrink: true }}
                value={newBroker.brokerPhone}
                onChange={(e) =>
                  setNewBroker({ ...newBroker, brokerPhone: e.target.value })
                }
                margin="dense"
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddBroker} color="primary">
            Submit
          </Button>
          <Button onClick={closeModal} color="secondary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
}

export default BrokersList;
