import React, { useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import { TextField, Button, CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "./Spinner";
import "./Edit.css";

function EditPod() {
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const podData = location.state?.podData;
    if (podData) {
      setEditData(podData);
    } else {
      navigate("/pod-history");
    }
  }, [location, navigate]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleUpdatePod = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`/api/update-pod`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(editData),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      alert("Order number updated successfully!");
      navigate("/pod-history");
    } catch (error) {
      console.error("Failed to update Order number:", error);
      alert(`Error updating Order number: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const editPdf = async (pdfUrl) => {
    const sasToken =
      "sp=racwd&st=2024-05-24T01:18:34Z&se=2025-09-01T09:18:34Z&spr=https&sv=2022-11-02&sr=c&sig=TmfTSU%2FdvAVNpmwoB2gHNJrq9NNejQgFNchUEn7OW0M%3D";
    const completeBlobUrl = `${pdfUrl}?${sasToken}`;

    try {
      const response = await fetch(completeBlobUrl);
      if (!response.ok)
        throw new Error("Failed to download PDF for modification");

      const existingPdfBytes = await response.arrayBuffer();
      const pdfDoc = await PDFDocument.load(existingPdfBytes);

      const modifiedPdfBytes = await pdfDoc.save();
      const mergedPdfBlob = new Blob([modifiedPdfBytes], {
        type: "application/pdf",
      });
      return mergedPdfBlob;
    } catch (error) {
      console.error("Failed to modify PDF:", error);
      throw new Error("Failed to modify PDF");
    }
  };

  const sendStatusEmail = async () => {
    const emails = editData.Email.split(",");
    const primaryEmail = emails.shift();
    const ccEmails = emails.join(",");
    const subject = `Invoice POD ${editData.OrderNumber} Status Request`;
    const body = `Hi,\nPlease provide the status of the invoice for order ${editData.OrderNumber}.\nThanks.`;
    const formData = new FormData();
    formData.append("toEmail", primaryEmail);
    formData.append("ccEmails", ccEmails);
    formData.append("subject", subject);
    formData.append("body", body);
    const modifiedPdfBlob = await editPdf(editData.PDF);
    formData.append("attachment", modifiedPdfBlob);
    try {
      const response = await fetch("/api/send-email-with-attachment-2", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        console.log(data.message);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      //   setMessage("An error occurred while sending the email.");
      console.error("Error:", error);
    }
  };

  const downloadPdf = async (blobUrlWithoutToken) => {
    setIsLoading(true);
    const sasToken =
      "sp=racwd&st=2024-05-24T01:18:34Z&se=2025-09-01T09:18:34Z&spr=https&sv=2022-11-02&sr=c&sig=TmfTSU%2FdvAVNpmwoB2gHNJrq9NNejQgFNchUEn7OW0M%3D";
    const completeBlobUrl = `${blobUrlWithoutToken}?${sasToken}`;

    try {
      const response = await fetch(completeBlobUrl);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const decodedFileName = decodeURIComponent(
        blobUrlWithoutToken.split("/").pop()
      );

      const a = document.createElement("a");
      a.href = downloadUrl;
      a.download = decodedFileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Failed to download file:", error);
    }
    setIsLoading(false);
  };

  return (
    <div className="container">
      {isLoading ? <Spinner /> : null}
      {editData && (
        <form onSubmit={handleUpdatePod} className="form-section">
          <div className="form-group">
            <label htmlFor="CompanyName">Company Name</label>
            <TextField
              type="text"
              name="CompanyName"
              value={editData.CompanyName}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-group">
            <label htmlFor="Email">Email</label>
            <TextField
              type="email"
              name="Email"
              value={editData.Email}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-group">
            <label htmlFor="EmailStatus">Email Status</label>
            <TextField
              type="text"
              name="EmailStatus"
              value={editData.EmailStatus}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-group">
            <label htmlFor="Paid">Paid</label>
            <TextField
              type="text"
              name="Paid"
              value={editData.Paid}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-group">
            <label htmlFor="Date">Date</label>
            <TextField
              type="text"
              name="Date"
              value={editData.Date}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="form-group">
            <label htmlFor="OrderNumber">Order Number</label>
            <TextField
              type="text"
              name="OrderNumber"
              value={editData.OrderNumber}
              onChange={handleInputChange}
              variant="outlined"
              fullWidth
            />
          </div>
          <div className="button-group">
            <Button onClick={sendStatusEmail} disabled={isLoading}>
              {isLoading ? <Spinner /> : "Send Status Email"}
            </Button>
          </div>
          <div className="button-group">
            <Button
              onClick={() => downloadPdf(editData.PDF)}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : "Download PDF"}
            </Button>
          </div>
          <div className="button-group">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              disabled={isLoading}
            >
              {isLoading ? <CircularProgress size={24} /> : "Update"}
            </Button>
            <Button
              onClick={() => navigate("/pod-history")}
              color="secondary"
              variant="outlined"
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </div>
  );
}

export default EditPod;
