import React from "react";
import { Modal, Button} from "@mui/material";
import "./ConfirmationModal.css"; 

function ConfirmationModal({ open, handleClose, handleConfirm, option }) {
  return (
    <Modal open={open} onClose={handleClose} aria-labelledby="modal-title">
      <div className="modal-box">
        <div className="modal-header">
          Confirm Chat Reset
        </div>
        <div className="modal-content">
          Are you sure you want to start a new chat in <b>{option}</b>? This
          will clear the current chat history.
        </div>
        <div className="modal-actions">
          <Button
            onClick={handleClose}
            className="modal-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            className="modal-confirm-button"
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
