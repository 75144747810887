import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Box,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "./logo2.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { InteractionStatus } from "@azure/msal-browser";

function Navbar({ instance, b2cPolicies, inProgress }) {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [customsAnchorEl, setCustomsAnchorEl] = useState(null);
  const [accountingAnchorEl, setAccountingAnchorEl] = useState(null);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const openProfileModal = () => setIsProfileModalOpen(true);
  const closeProfileModal = () => setIsProfileModalOpen(false);

  const handleProfileEdit = () => {
    closeProfileModal();
    if (
      inProgress === InteractionStatus.None &&
      b2cPolicies &&
      b2cPolicies.authorities &&
      b2cPolicies.authorities.editProfile
    ) {
      instance
        .acquireTokenRedirect(b2cPolicies.authorities.editProfile)
        .catch(console.error);
    } else {
      console.error("b2cPolicies or authorities not properly defined!");
    }
  };

  const handleLogout = () => {
    closeProfileModal();
    instance.logoutRedirect();
  };

  const handleMenuOpen = (event, setAnchorEl) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (setAnchorEl) => {
    setAnchorEl(null);
  };

  const drawerList = () => (
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={(e) => handleMenuOpen(e, setCustomsAnchorEl)}>
          <ListItemText primary="Customs" />
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={customsAnchorEl}
        open={Boolean(customsAnchorEl)}
        onClose={() => handleMenuClose(setCustomsAnchorEl)}
      >
        {["Home", "Broker List", "PAPS", "PARS"].map((text) => (
          <MenuItem
            key={text}
            onClick={() => {
              handleMenuClose(setCustomsAnchorEl);
              setDrawerOpen(false);
            }}
            component={Link}
            to={`/${text.toLowerCase().replace(" ", "-")}`}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
      <ListItem disablePadding>
        <ListItemButton
          onClick={(e) => handleMenuOpen(e, setAccountingAnchorEl)}
        >
          <ListItemText primary="Accounting" />
        </ListItemButton>
      </ListItem>
      <Menu
        anchorEl={accountingAnchorEl}
        open={Boolean(accountingAnchorEl)}
        onClose={() => handleMenuClose(setAccountingAnchorEl)}
      >
        {["Pod", "Pod History"].map((text) => (
          <MenuItem
            key={text}
            onClick={() => {
              handleMenuClose(setAccountingAnchorEl);
              setDrawerOpen(false);
            }}
            component={Link}
            to={`/${text.toLowerCase().replace(" ", "-")}`}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/edit-pdf" // Add this line to link to the PdfEditor component
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemText primary="Edit PDF" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton
          component={Link}
          to="/chat" // Add this line to link to the PdfEditor component
          onClick={() => setDrawerOpen(false)}
        >
          <ListItemText primary="Chat" />
        </ListItemButton>
      </ListItem>
    </List>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: "black" }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
          onClick={() => setDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Link to="/chat" style={{ color: "inherit", textDecoration: "none" }}>
            <img src={logo} alt="LoadMinds Logo" style={{ height: "65px" }} />
          </Link>
        </Typography>
        <Button
          color="inherit"
          onClick={openProfileModal}
          sx={{ fontSize: "24px" }}
        >
          <FontAwesomeIcon
            icon={faUserCircle}
            style={{ height: "24px", width: "24px" }}
          />
        </Button>
      </Toolbar>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        {drawerList()}
      </Drawer>
      <Modal
        open={isProfileModalOpen}
        onClose={closeProfileModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Profile Options
          </Typography>
          <Button onClick={handleProfileEdit} sx={{ my: 2 }}>
            Edit Profile
          </Button>
          <Button onClick={handleLogout} sx={{ my: 2 }}>
            Logout
          </Button>
          <Button onClick={closeProfileModal} sx={{ my: 2 }}>
            Close
          </Button>
        </Box>
      </Modal>
    </AppBar>
  );
}

export default Navbar;
