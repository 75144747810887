import React, { useState, useEffect } from "react";
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";
import { TextField, Button, Dialog } from "@mui/material";
import Spinner from "./Spinner";
import PdfEditor from "./PdfEditor";
import Modal from "react-modal";
import IconButton from "@mui/material/IconButton";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";

function Pod() {
  const [combinedPdfBlob, setCombinedPdfBlob] = useState(null);
  const [loadConfirmationFiles, setLoadConfirmationFiles] = useState([]);
  const [podFiles, setPodFiles] = useState([]);
  const [storageUserId, setStorageUserId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceNr, setInvoiceNr] = useState("3445");
  const [quickPay, setQuickPay] = useState(0);
  const initialShipper = {
    name: "Sample",
    address: "Sample Street",
    cityState: "Mission, BC",
    shortFormPickupName: "BC",
    zip: "TEST 1A2",
    date: "07/05/24",
  };
  let fileName = "combined.pdf";

  const initialConsignee = {
    name: "Sample 2",
    address: "Sample Street 2",
    cityState: "Vancouver BC",
    shortFormDeliveryName: "AB",
    zip: "TEST 2B3",
    date: "07/08/24",
  };
  const [shippers, setShippers] = useState([initialShipper]);
  const [consignees, setConsignees] = useState([initialConsignee]);

  const [company, setCompany] = useState({
    name: "Sample Company",
    shortForm: "SC",
    address: "Sample Company Street",
    cityState: "Vancouver BC",
    zip: "TEST 3C4",
    orderNumber: "8758721",
    price: "5000",
    currency: "CAD",
    dispatchEmail: "ssd19@sfu.ca",
    accountingEmail: "ssd19@sfu.ca",
    phone: "7788828652",
  });
  const [charges, setCharges] = useState([
    { reason: "Quick Pay", type: "percentage", value: 0, id: 1 },
  ]);

  const [finalBalance, setFinalBalance] = useState(parseFloat(company.price));
  // Start with company price as initial balance
  const [modalOpen, setModalOpen] = useState(false);
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleCompanyChange = (field, value) => {
    setCompany((prev) => ({ ...prev, [field]: value }));
  };

  const [message, setMessage] = useState("");
  const [isPdfEditorOpen, setIsPdfEditorOpen] = useState(false);
  // const [editedPdfBlob, setEditedPdfBlob] = useState(null);

  const togglePdfEditorModal = () => {
    setIsPdfEditorOpen(!isPdfEditorOpen);
  };

  const handleFileChange = (e, setFiles) => {
    setFiles(Array.from(e.target.files));
  };

  const handleShipperChange = (index, field, value) => {
    setShippers((prev) => {
      const updatedShippers = [...prev];
      updatedShippers[index] = { ...updatedShippers[index], [field]: value };
      return updatedShippers;
    });
  };

  useEffect(() => {
    const totalCharges = charges.reduce((total, charge) => {
      const chargeAmount =
        charge.type === "percentage"
          ? (parseFloat(company.price) * charge.value) / 100
          : parseFloat(charge.value);

      // Apply charge as an addition or deduction
      return charge.operation === "subtract"
        ? total - chargeAmount // Deduct the amount
        : total + chargeAmount; // Add the amount
    }, 0);

    setFinalBalance(parseFloat(company.price) + totalCharges);
  }, [charges, company.price]);

  const handleChargeChange = (index, field, value) => {
    setCharges((prevCharges) => {
      const updatedCharges = [...prevCharges];
      updatedCharges[index] = { ...updatedCharges[index], [field]: value };

      // If the charge reason contains "Quick Pay"
      if (updatedCharges[index].reason.toLowerCase().includes("quick pay")) {
        setQuickPay(1); // Set quick pay to 1 if "Quick Pay" is part of the reason
      } else {
        setQuickPay(0); // Reset to 0 if "Quick Pay" is not found
      }

      return updatedCharges;
    });
  };

  const addCharge = () => {
    setCharges([
      ...charges,
      {
        reason: "",
        type: "amount",
        value: 0,
        operation: "add",
        id: charges.length + 1,
      },
    ]);
  };

  const removeCharge = (index) => {
    setCharges(charges.filter((_, i) => i !== index));
  };

  const handleConsigneeChange = (index, field, value) => {
    setConsignees((prev) => {
      const updatedConsignees = [...prev];
      updatedConsignees[index] = {
        ...updatedConsignees[index],
        [field]: value,
      };
      return updatedConsignees;
    });
  };

  const blobToBase64 = (blob) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    const storedUserId = sessionStorage.getItem("storageUserId");
    if (storedUserId) {
      setStorageUserId(storedUserId);
      console.log("User ID:", storedUserId);
    }
  }, []);

  const uploadToBlobStorage = async (pdfBlob, fileName) => {
    try {
      const base64Content = await blobToBase64(pdfBlob);
      // Strip off the base64 prefix
      const base64Data = base64Content.replace(/^data:.+;base64,/, "");

      const body = JSON.stringify({
        blobName: fileName,
        blobContent: base64Data,
      });

      const response = await fetch("/api/save-to-blob", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      const data = await response.json();
      if (response.ok) {
        console.log("PDF uploaded successfully: ", data.url);
        return data.url; // URL of the uploaded PDF in blob storage
      } else {
        throw new Error(data.error || "Failed to upload PDF");
      }
    } catch (error) {
      console.error("Error uploading PDF to blob storage: ", error);
      setMessage(`Error: ${error.message}`);
      return null;
    }
  };

  // const mergeAndDownloadPDF = async () => {
  //   setIsLoading(true);
  //   try {
  //     // Create a new PDFDocument if no combined PDF exists
  //     const mergedPdf = await PDFDocument.create();

  //     // Create the invoice PDF and add it to the merged document
  //     const invoiceBlob = await createInvoice(invoice);
  //     if (invoiceBlob) {
  //       const invoiceBytes = await invoiceBlob.arrayBuffer();
  //       const invoicePdf = await PDFDocument.load(invoiceBytes);
  //       const invoicePages = await mergedPdf.copyPages(
  //         invoicePdf,
  //         invoicePdf.getPageIndices()
  //       );
  //       invoicePages.forEach((page) => mergedPdf.addPage(page));
  //     }

  //     // Add load confirmation files to the merged PDF
  //     await addFilesToDocument(loadConfirmationFiles, mergedPdf);

  //     // Add POD files to the merged PDF
  //     await addFilesToDocument(podFiles, mergedPdf);

  //     // Save the merged PDF as a Blob
  //     const mergedPdfBytes = await mergedPdf.save();
  //     const mergedPdfBlob = new Blob([mergedPdfBytes], {
  //       type: "application/pdf",
  //     });
  //     setCombinedPdfBlob(mergedPdfBlob); // Store the combined PDF blob in state

  //     // Generate the file name dynamically with shippers and consignees
  //     fileName = `${company.shortForm} ${shippers[0].shortFormPickupName}-${consignees[0].shortFormDeliveryName} ${company.price}.pdf`;
  //     downloadPDF(combinedPdfBlob, fileName);

  //     // Upload the PDF to Blob storage
  //     const blobUrl = await uploadToBlobStorage(combinedPdfBlob, fileName);
  //     if (!blobUrl) {
  //       throw new Error("Failed to upload PDF to blob storage.");
  //     }
  //     // Store the PDF in the database with necessary information
  //     await sendToDatabase(blobUrl);

  //     // Send the email with the merged PDF as an attachment
  //     await sendEmailWithAttachment(combinedPdfBlob);

  //     setMessage("PDFs combined, uploaded, and email sent successfully!");
  //   } catch (error) {
  //     console.error("Error during PDF processing:", error);
  //     setMessage(`Error: ${error.message}`);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const downloadPDF = (blob, fileName) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;

    // Programmatically click the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up the URL object
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const mergeAndDownloadPDF2 = async () => {
    setIsLoading(true);
    try {
      const invoiceBlob = await createInvoice(invoice);
      const mergedPdf = await PDFDocument.create();

      if (invoiceBlob) {
        const invoiceBytes = await invoiceBlob.arrayBuffer();
        const invoicePdf = await PDFDocument.load(invoiceBytes);
        const [invoicePage] = await mergedPdf.copyPages(
          invoicePdf,
          invoicePdf.getPageIndices()
        );
        mergedPdf.addPage(invoicePage);
      }

      // Merge loadConfirmationFiles and podFiles
      await addFilesToDocument(loadConfirmationFiles, mergedPdf);
      await addFilesToDocument(podFiles, mergedPdf);

      const mergedPdfBytes = await mergedPdf.save();
      const mergedPdfBlob = new Blob([mergedPdfBytes], {
        type: "application/pdf",
      });

      // Set the combined PDF blob in the state to be passed to PdfEditor.js
      setCombinedPdfBlob(mergedPdfBlob);

      // Open PdfEditor.js with the combinedPdfBlob for editing
      togglePdfEditorModal(); // Function to open the editor modal

      setMessage("Document generated successfully. You can now edit the PDF.");
    } catch (error) {
      console.error("Error during PDF creation:", error);
      setMessage("Failed to generate document: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Function to add a new shipper
  const addShipper = () => {
    setShippers((prevShippers) => [
      ...prevShippers,
      {
        name: "",
        address: "",
        cityState: "",
        zip: "",
        date: "",
        shortFormPickupName: "",
      },
    ]);
  };

  // Function to remove a shipper by index
  const removeShipper = (index) => {
    setShippers((prevShippers) => prevShippers.filter((_, i) => i !== index));
  };

  const extractEverything = async (loadConfirmationFiles) => {
    if (loadConfirmationFiles.length === 0) return;
    setIsLoading(true); // Show loading spinner while processing

    const file = loadConfirmationFiles[0];
    const fileBytes = await file.arrayBuffer();
    const pdfBlob = new Blob([fileBytes], { type: "application/pdf" });

    try {
      const formData = new FormData();
      formData.append("pdf", pdfBlob);

      const response = await fetch("/api/extract-everything", {
        method: "POST",
        body: formData,
      });

      const data = await response.json();
      if (!response.ok) throw new Error(data.error || "Failed to analyze PDF");

      console.log("Extracted Data:", data);

      // Update company details
      setCompany((prev) => ({
        ...prev,
        name: data.company || prev.name,
        shortForm: data.companyShortForm || prev.shortForm,
        orderNumber: data.orderNumber || prev.orderNumber,
        dispatchEmail: data.dispatchEmail || prev.dispatchEmail,
        accountingEmail: data.accountingEmail || prev.accountingEmail,
        price: data.price || prev.price,
        currency: data.currency || prev.currency,
        address: data.companyAddress || prev.address,
        cityState: data.companyCityState || prev.cityState,
        zip: data.companyZip || prev.zip,
        phone: data.companyPhone || prev.phone,
      }));

      // Update the first shipper (shipping)
      setShippers((prevShippers) => {
        const updatedShippers = [...prevShippers];
        updatedShippers[0] = {
          ...updatedShippers[0],
          name: data.shipper_name || updatedShippers[0].name,
          address: data.shipping_address || updatedShippers[0].address,
          cityState: data.shipping_city_state || updatedShippers[0].cityState,
          zip: data.shipping_zip || updatedShippers[0].zip,
          date: data.loadingDate || updatedShippers[0].date,
          shortFormPickupName:
            data.loadingLocationShortForm ||
            updatedShippers[0].shortFormPickupName,
        };
        return updatedShippers;
      });

      // Update the first consignee (receiving)
      setConsignees((prevConsignees) => {
        const updatedConsignees = [...prevConsignees];
        updatedConsignees[0] = {
          ...updatedConsignees[0],
          name: data.receiver_name || updatedConsignees[0].name,
          address: data.receiving_address || updatedConsignees[0].address,
          cityState:
            data.receiving_city_state || updatedConsignees[0].cityState,
          zip: data.receiving_zip || updatedConsignees[0].zip,
          date: data.deliveryDate || updatedConsignees[0].date,
          shortFormDeliveryName:
            data.deliveryLocationShortForm ||
            updatedConsignees[0].shortFormDeliveryName,
        };
        return updatedConsignees;
      });

      setMessage(`PDF analyzed: ${data.summary}`);
    } catch (error) {
      console.error("Error analyzing PDF:", error);
      setMessage(`Error analyzing PDF: ${error.message}`);
    } finally {
      setIsLoading(false); // Hide loading spinner
    }
  };

  const addFilesToDocument = async (files, pdfDoc) => {
    for (const file of files) {
      const arrayBuffer = await readFileAsArrayBuffer(file);
      const pdf = await PDFDocument.load(arrayBuffer);
      const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
      copiedPages.forEach((page) => pdfDoc.addPage(page));
    }
  };

  const sendToDatabase = async (blobUrl) => {
    try {
      const emailField = `${company.dispatchEmail}, ${company.accountingEmail}`;
      const body = {
        CompanyName: company.name,
        Email: emailField,
        PDF: blobUrl,
        OrderNumber: company.orderNumber,
        UserId: storageUserId,
      };
      const response = await fetch("/api/store-podhistory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      // Check if the response has content and is in JSON format
      if (
        response.ok &&
        response.headers.get("content-type")?.includes("application/json")
      ) {
        const data = await response.json();
        setMessage(`Update successful: ${data.message}`);
      } else {
        throw new Error("Received non-JSON response or no data from server.");
      }
    } catch (error) {
      setMessage(`Error: ${error.message}`);
      console.error("Failed to update Pars row:", error);
    }
  };

  const readFileAsArrayBuffer = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  };

  const handleLoadConfirmationAnalysis = async () => {
    if (loadConfirmationFiles.length === 0) return;

    const file = loadConfirmationFiles[0];
    if (!file) return;

    setIsLoading(true); // Show loading spinner before processing starts
    try {
      await extractEverything(loadConfirmationFiles);
    } catch (error) {
      console.error("Error during analysis:", error);
      setMessage(`Error during analysis: ${error.message}`);
    } finally {
      setIsLoading(false); // Hide loading spinner after all processing is done
    }
  };

  const sendEmailWithAttachment = async (combinedPdfBlob) => {
    downloadPDF(combinedPdfBlob, "combined.pdf");
    const formData = new FormData();

    // Handle multiple dispatch emails (assuming they are comma-separated)
    const emails = company.dispatchEmail
      .split(",")
      .map((email) => email.trim());
    const ccEmails = emails.join(",");

    formData.append("toEmail", company.accountingEmail); // Send to accounting email
    formData.append("ccEmails", ccEmails); // CC to dispatch emails
    formData.append("orderNumber", company.orderNumber);
    formData.append("companyName", company.shortForm);
    formData.append("price", company.price);
    formData.append("currency", company.currency);
    formData.append("quickPay", quickPay);

    // Use the first shipper and consignee for the email content (additional ones must be handled manually)
    formData.append(
      "shippingName",
      shippers[0]?.shortFormPickupName || "Unknown"
    );
    formData.append(
      "receivingName",
      consignees[0]?.shortFormDeliveryName || "Unknown"
    );

    // Attach the PDF
    formData.append("attachment", combinedPdfBlob);

    try {
      const response = await fetch("/api/send-email-with-attachment-pod", {
        method: "POST",
        body: formData,
      });

      // Handle the API response
      const data = await response.json();
      if (response.ok) {
        setMessage(data.message); // Success message from the API
      } else {
        setMessage(data.error || "Failed to send email.");
      }
    } catch (error) {
      // Catch any network or unexpected errors
      setMessage("An error occurred while sending the email.");
      console.error("Error:", error);
    }
  };

  const addConsignee = () => setConsignees([...consignees, initialConsignee]);
  const removeConsignee = (index) =>
    setConsignees(consignees.filter((_, i) => i !== index));

  const invoiceDate = new Date().toLocaleDateString("en-US", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
  });

  const invoice = {
    invoice_nr: invoiceNr,
    invoice_date: invoiceDate,
    order_nr: company.orderNumber,
    bill_to: company.name,
    bill_address: `${company.address}, ${company.cityState}, ${company.zip}`,
    bill_phone: company.phone,
    initialPrice: parseFloat(company.price), // Ensure initial price is correctly parsed
    finalBalance: finalBalance, // Use the computed final balance
    charges: charges, // Include the list of charges
    currency: company.currency,
    shippers: shippers.map((shipper) => ({
      name: shipper.name,
      address: shipper.address,
      cityState: shipper.cityState,
      zip: shipper.zip,
      date: shipper.date,
      shortFormPickupName: shipper.shortFormPickupName,
    })),
    consignees: consignees.map((consignee) => ({
      name: consignee.name,
      address: consignee.address,
      cityState: consignee.cityState,
      zip: consignee.zip,
      date: consignee.date,
      shortFormDeliveryName: consignee.shortFormDeliveryName,
    })),
    items: shippers.map((shipper, index) => ({
      shipper: shipper.name,
      shipper_address: shipper.address,
      shipper_address2: shipper.cityState,
      shipper_address3: shipper.zip,
      ship_date: shipper.date,
      consignee: consignees[index]?.name || "",
      consignee_address: consignees[index]?.address || "",
      consignee_address2: consignees[index]?.cityState || "",
      consignee_address3: consignees[index]?.zip || "",
      delivery_date: consignees[index]?.date || "",
      amount: company.price,
    })),
  };

  async function createInvoice(invoice) {
    const doc = await PDFDocument.create();
    const boldFont = await doc.embedFont(StandardFonts.HelveticaBold); // Embed at the top level
    const regularFont = await doc.embedFont(StandardFonts.Helvetica);
    let logoImage; // Declare logoImage at a higher scope to make it accessible throughout the function

    const baseUrl = window.location.origin;
    const logoUrl = `${baseUrl}/test.jpeg.png`;

    try {
      const response = await fetch(logoUrl, {
        cache: "no-cache", // Bypass the cache to ensure the latest version is fetched
        headers: {
          Pragma: "no-cache", // Compatibility with HTTP/1.0 caches
          "Cache-Control": "no-cache, no-store, must-revalidate", // Comprehensive directives for various cache policies
        },
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch logo: ${response.statusText}`);
      }
      const logoImageBytes = await response.arrayBuffer();
      logoImage = await doc.embedPng(logoImageBytes); // Assign the embedded image to logoImage
    } catch (error) {
      console.error("Error fetching or embedding the logo:", error);
    }

    // Now you can use the logo in your PDF
    const page = doc.addPage();
    const { width, height } = page.getSize();
    console.log("Width:", width, "Height:", height);

    if (logoImage) {
      // Place the logo at desired position only if it's defined
      page.drawImage(logoImage, {
        x: 400, // Adjust position as necessary
        y: height - 100 - 30, // Subtract the height of the image (100) and an additional top margin (30)
        width: 150, // Adjust size as necessary
        height: 70, // Adjust size as necessary
      });
    } else {
      console.log("Logo image not available");
    }

    generateHeader(page, doc, boldFont, regularFont);
    generateCustomerInformation(page, doc, invoice, boldFont, regularFont);
    generateInvoiceDetails(page, doc, invoice, boldFont, regularFont);
    generateFooter(page, doc, boldFont, regularFont);

    const pdfBytes = await doc.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  }

  const sendEmail = async () => {
    if (!combinedPdfBlob) {
      setMessage("Please generate a PDF first.");
      return;
    }

    const blobUrl = await uploadToBlobStorage(combinedPdfBlob, fileName);
    if (!blobUrl) {
      throw new Error("Failed to upload PDF to blob storage.");
    }

    await sendToDatabase(blobUrl);

    setIsLoading(true);
    downloadPDF(combinedPdfBlob, "attachment.pdf");

    //sendNumberToBackend(number);
    await sendEmailWithAttachment(combinedPdfBlob);

    setIsLoading(false);
  };

  async function generateHeader(page, doc, boldFont, regularFont) {
    const headerContent = [
      { text: "MG Transport Ltd", font: boldFont, size: 22, yPos: 780 },
      {
        text: "33203 Rose Ave, Mission BC, V2V 4Z4",
        font: boldFont,
        size: 10,
        yPos: 760,
      },
      { text: "Tel: 778-344-4993", font: boldFont, size: 10, yPos: 745 },
      { text: "Fax: 778-653-0993", font: boldFont, size: 10, yPos: 730 },
      { text: "mgtransport@live.ca", font: boldFont, size: 10, yPos: 715 },
    ];

    headerContent.forEach((item) => {
      page.drawText(item.text, {
        x: 50, // Constant x position for alignment
        y: item.yPos, // Adjusted y position for each line
        size: item.size,
        font: item.font,
        color: rgb(0, 0, 0),
      });
    });
  }

  async function generateCustomerInformation(
    page,
    doc,
    invoice,
    boldFont,
    regularFont
  ) {
    let yPosition = 670;

    page.drawText("Bill To:", {
      x: 50,
      y: yPosition,
      size: 15,
      font: boldFont,
      color: rgb(0, 0, 0),
    });

    yPosition -= 20;

    page.drawText(`${invoice.bill_to}`, {
      x: 50,
      y: yPosition,
      size: 12,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    yPosition -= 15;
    page.drawText(`${invoice.bill_address}`, {
      x: 50,
      y: yPosition,
      size: 12,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    yPosition -= 15;
    page.drawText(`Tel: ${invoice.bill_phone}`, {
      x: 50,
      y: yPosition,
      size: 12,
      font: regularFont,
      color: rgb(0, 0, 0),
    });

    yPosition = 670; // Reset to original y position for right-aligned details
    page.drawText("Invoice Date:", {
      x: 400,
      y: yPosition,
      size: 12,
      font: boldFont, // Bold font for label
      color: rgb(0, 0, 0),
      align: "right",
    });

    page.drawText(`${invoice.invoice_date}`, {
      x: 540, // Right align text
      y: yPosition,
      size: 12,
      font: regularFont, // Regular font for value
      color: rgb(0, 0, 0),
    });

    yPosition -= 15; // Decrement for invoice number
    page.drawText("Invoice Number:", {
      x: 400,
      y: yPosition,
      size: 12,
      font: boldFont, // Bold font for label
      color: rgb(0, 0, 0),
      align: "right",
    });

    page.drawText(`${invoice.invoice_nr}`, {
      x: 540, // Right align text
      y: yPosition,
      size: 12,
      font: regularFont, // Regular font for value
      color: rgb(0, 0, 0),
    });

    yPosition -= 15; // Decrement for customer order number
    page.drawText("Customer Order #:", {
      x: 400,
      y: yPosition,
      size: 12,
      font: boldFont, // Bold font for label
      color: rgb(0, 0, 0),
      align: "right",
    });

    page.drawText(`${invoice.order_nr}`, {
      x: 540, // Right align text
      y: yPosition,
      size: 12,
      font: regularFont, // Regular font for value
      color: rgb(0, 0, 0),
    });
  }

  async function generateInvoiceDetails(
    page,
    doc,
    invoice,
    boldFont,
    regularFont
  ) {
    let yPosition = 600; // Starting position for the invoice details

    // Draw a line above the details
    page.drawLine({
      start: { x: 50, y: yPosition },
      end: { x: 550, y: yPosition },
      color: rgb(0.667, 0.667, 0.667),
      thickness: 1,
    });

    // Iterate over each shipper in the invoice
    invoice.shippers.forEach((shipper, index) => {
      yPosition -= 15; // Space before starting the new entry

      // Shipper Information
      page.drawText("Shipper:", {
        x: 50,
        y: yPosition,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
      page.drawText(shipper.name, {
        x: 150,
        y: yPosition,
        size: 12,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      yPosition -= 15; // Move down for shipper address
      page.drawText("Address:", {
        x: 50,
        y: yPosition,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
      page.drawText(
        `${shipper.address}, ${shipper.cityState}, ${shipper.zip}`,
        {
          x: 150,
          y: yPosition,
          size: 12,
          font: regularFont,
          color: rgb(0, 0, 0),
        }
      );

      yPosition -= 15; // Move down for shipping date
      page.drawText("Shipping Date:", {
        x: 50,
        y: yPosition,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
      page.drawText(shipper.date, {
        x: 150,
        y: yPosition,
        size: 12,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      yPosition -= 20; // Space before next entry
      // Draw a line before the next shipper
      page.drawLine({
        start: { x: 50, y: yPosition },
        end: { x: 550, y: yPosition },
        color: rgb(0.667, 0.667, 0.667),
        thickness: 1,
      });

      yPosition -= 15; // Prepare space for next entry
    });

    // After all shippers, list the consignee(s) (Assume one consignee if none added)
    if (invoice.consignees.length > 0) {
      invoice.consignees.forEach((consignee) => {
        yPosition -= 15; // Space before consignee information

        // Consignee Information
        page.drawText("Consignee:", {
          x: 50,
          y: yPosition,
          size: 12,
          font: boldFont,
          color: rgb(0, 0, 0),
        });
        page.drawText(consignee.name, {
          x: 150,
          y: yPosition,
          size: 12,
          font: regularFont,
          color: rgb(0, 0, 0),
        });

        yPosition -= 15; // Move down for consignee address
        page.drawText("Address:", {
          x: 50,
          y: yPosition,
          size: 12,
          font: boldFont,
          color: rgb(0, 0, 0),
        });
        page.drawText(
          `${consignee.address}, ${consignee.cityState}, ${consignee.zip}`,
          {
            x: 150,
            y: yPosition,
            size: 12,
            font: regularFont,
            color: rgb(0, 0, 0),
          }
        );

        yPosition -= 15; // Move down for delivery date
        page.drawText("Delivery Date:", {
          x: 50,
          y: yPosition,
          size: 12,
          font: boldFont,
          color: rgb(0, 0, 0),
        });
        page.drawText(consignee.date, {
          x: 150,
          y: yPosition,
          size: 12,
          font: regularFont,
          color: rgb(0, 0, 0),
        });

        yPosition -= 20; // Space before price and balance due
        page.drawLine({
          start: { x: 50, y: yPosition },
          end: { x: 550, y: yPosition },
          color: rgb(0.667, 0.667, 0.667),
          thickness: 1,
        });
      });
    } else {
      // Default case where no consignee is added manually
      const defaultConsignee = invoice.consignees[0]; // Use the first consignee
      page.drawText("Consignee:", {
        x: 50,
        y: yPosition,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
      page.drawText(defaultConsignee.name, {
        x: 150,
        y: yPosition,
        size: 12,
        font: regularFont,
        color: rgb(0, 0, 0),
      });

      yPosition -= 15; // Move down for consignee address
      page.drawText("Address:", {
        x: 50,
        y: yPosition,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
      page.drawText(
        `${defaultConsignee.address}, ${defaultConsignee.cityState}, ${defaultConsignee.zip}`,
        {
          x: 150,
          y: yPosition,
          size: 12,
          font: regularFont,
          color: rgb(0, 0, 0),
        }
      );

      yPosition -= 15; // Move down for delivery date
      page.drawText("Delivery Date:", {
        x: 50,
        y: yPosition,
        size: 12,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
      page.drawText(defaultConsignee.date, {
        x: 150,
        y: yPosition,
        size: 12,
        font: regularFont,
        color: rgb(0, 0, 0),
      });
    }

    // Finally, display the price and balance once
    yPosition -= 20; // Space before price and balance due
    page.drawLine({
      start: { x: 50, y: yPosition },
      end: { x: 550, y: yPosition },
      color: rgb(0.667, 0.667, 0.667),
      thickness: 1,
    });

    // Display initial price
    yPosition -= 15;
    page.drawText("Initial Price:", {
      x: 50,
      y: yPosition,
      size: 12,
      font: boldFont,
      color: rgb(0, 0, 0),
    });
    page.drawText(
      `$${parseFloat(invoice.initialPrice).toFixed(2)} ${invoice.currency}`,
      {
        x: 400, // Align the price to the right
        y: yPosition,
        size: 12,
        font: regularFont,
        color: rgb(0, 0, 0),
      }
    );

    // Display additional charges only if they are greater than 0
    const charges = invoice.charges || []; // Fallback to empty array if undefined
    const hasCharges = charges.some((charge) => charge.value > 0);

    if (hasCharges) {
      yPosition -= 20;

      charges.forEach((charge) => {
        if (charge.value > 0) {
          yPosition -= 15;
          const sign = charge.operation === "subtract" ? "-" : "";
          const chargeAmount =
            charge.type === "percentage"
              ? (parseFloat(invoice.initialPrice) * charge.value) / 100
              : charge.value;

          const chargeLabel =
            charge.type === "percentage"
              ? `${charge.reason} (${charge.value}%):`
              : `${charge.reason} (${charge.type}):`;

          page.drawText(chargeLabel, {
            x: 50,
            y: yPosition,
            size: 12,
            font: boldFont,
            color: rgb(0, 0, 0),
          });

          page.drawText(
            `${sign}$${parseFloat(chargeAmount).toFixed(2)} ${
              invoice.currency
            }`,
            {
              x: 400, // Align the amount to the right
              y: yPosition,
              size: 12,
              font: regularFont,
              color: rgb(0, 0, 0),
            }
          );
        }
      });
    }

    // Display final balance
    yPosition -= 20;
    page.drawLine({
      start: { x: 50, y: yPosition },
      end: { x: 550, y: yPosition },
      color: rgb(0.667, 0.667, 0.667),
      thickness: 1,
    });

    yPosition -= 15;
    page.drawText("Final Balance Due:", {
      x: 50,
      y: yPosition,
      size: 12,
      font: boldFont,
      color: rgb(0, 0, 0),
    });
    page.drawText(
      `$${parseFloat(invoice.finalBalance).toFixed(2)} ${invoice.currency}`,
      {
        x: 400, // Align the final balance to the right
        y: yPosition,
        size: 12,
        font: regularFont,
        color: rgb(0, 0, 0),
      }
    );
  }

  async function generateFooter(page, doc) {
    const font = await doc.embedFont(StandardFonts.Helvetica);

    // Central footer text
    const footerText1 = "Thank you for your business!";
    const footerText2 = "www.mgtransportltd.com";
    const footerText3 = "Page 1 of 1";

    // Calculate text width to center
    const textWidth1 = font.widthOfTextAtSize(footerText1, 10);
    const textWidth2 = font.widthOfTextAtSize(footerText2, 10);
    const textWidth3 = font.widthOfTextAtSize(footerText3, 10);

    const pageWidth = page.getSize().width;

    // Draw centered text
    page.drawText(footerText1, {
      x: (pageWidth - textWidth1) / 2,
      y: 50, // Adjust this value as needed to position at the desired bottom offset
      size: 10,
      font: font,
      color: rgb(0, 0, 0),
    });

    page.drawText(footerText2, {
      x: (pageWidth - textWidth2) / 2,
      y: 35, // Position slightly above the first line
      size: 10,
      font: font,
      color: rgb(0, 0, 0),
    });

    page.drawText(footerText3, {
      x: (pageWidth - textWidth3) / 2,
      y: 20, // Position even further up
      size: 10,
      font: font,
      color: rgb(0, 0, 0),
    });
  }

  return (
    <div className="container" style={{ marginTop: "50px" }}>
      <div className="form-group">
        <label htmlFor="loadConfirmationUpload">
          Upload Load Confirmation:
        </label>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ backgroundColor: "#3c94fc" }}
        >
          Upload Load Confirmation
          <input
            type="file"
            id="loadConfirmationUpload"
            multiple
            accept="application/pdf"
            hidden
            onChange={(e) => handleFileChange(e, setLoadConfirmationFiles)}
          />
        </Button>
      </div>
      <div className="form-group">
        <label htmlFor="podUpload">Upload POD:</label>
        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ backgroundColor: "#3c94fc" }}
        >
          Upload POD
          <input
            type="file"
            id="podUpload"
            multiple
            accept="application/pdf"
            hidden
            onChange={(e) => handleFileChange(e, setPodFiles)}
          />
        </Button>
      </div>
      <div className="form-group">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="send-button"
            onClick={handleLoadConfirmationAnalysis}
            disabled={loadConfirmationFiles.length === 0}
          >
            {isLoading ? <Spinner /> : "Analyze Load Confirmation"}
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="send-button"
            onClick={mergeAndDownloadPDF2}
            disabled={loadConfirmationFiles.length === 0}
          >
            {isLoading ? <Spinner /> : "Generate Invoice"}
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="send-button"
            onClick={toggleModal}
            disabled={loadConfirmationFiles.length === 0}
          >
            {isLoading ? <Spinner /> : "Edit Information"}
          </Button>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            className="send-button"
            onClick={sendEmail}
            disabled={
              podFiles.length === 0 || loadConfirmationFiles.length === 0
            }
          >
            {isLoading ? <Spinner /> : "Send"}
          </Button>
        </div>
      </div>
      <p className="message">{message}</p>

      <Dialog open={modalOpen} onClose={toggleModal} fullWidth maxWidth="md">
        <div className="form-group">
          <h2>Shipping Information</h2>
          {shippers.map((shipper, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <TextField
                label="Shipper Name"
                value={shipper.name}
                onChange={(e) =>
                  handleShipperChange(index, "name", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Address"
                value={shipper.address}
                onChange={(e) =>
                  handleShipperChange(index, "address", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="City/State"
                value={shipper.cityState}
                onChange={(e) =>
                  handleShipperChange(index, "cityState", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Zip"
                value={shipper.zip}
                onChange={(e) =>
                  handleShipperChange(index, "zip", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Date"
                value={shipper.date}
                onChange={(e) =>
                  handleShipperChange(index, "date", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <div className="form-group">
                <label htmlFor={`pickupLocation-${index}`}>
                  Pickup Location:
                </label>
                <TextField
                  type="text"
                  id={`pickupLocation-${index}`}
                  value={shipper.shortFormPickupName}
                  onChange={(e) =>
                    handleShipperChange(
                      index,
                      "shortFormPickupName",
                      e.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                />
              </div>

              {/* Remove Shipper Button */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <IconButton
                  onClick={() => removeShipper(index)}
                  color="secondary"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            </div>
          ))}

          {/* Add Shipper Button */}
          <Button
            onClick={addShipper}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            Add Shipper
          </Button>
        </div>

        {/* Receiving Information Editable Fields */}
        <div className="form-group">
          <h2>Receiving Information</h2>
          {consignees.map((consignee, index) => (
            <div key={index} style={{ marginBottom: "20px" }}>
              <TextField
                label="Consignee Name"
                value={consignee.name}
                onChange={(e) =>
                  handleConsigneeChange(index, "name", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Address"
                value={consignee.address}
                onChange={(e) =>
                  handleConsigneeChange(index, "address", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="City/State"
                value={consignee.cityState}
                onChange={(e) =>
                  handleConsigneeChange(index, "cityState", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Zip"
                value={consignee.zip}
                onChange={(e) =>
                  handleConsigneeChange(index, "zip", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Date"
                value={consignee.date}
                onChange={(e) =>
                  handleConsigneeChange(index, "date", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <div className="form-group">
                <label htmlFor={`deliveryLocation-${index}`}>
                  Delivery Location:
                </label>
                <TextField
                  type="text"
                  id={`deliveryLocation-${index}`}
                  value={consignee.shortFormDeliveryName}
                  onChange={(e) =>
                    handleConsigneeChange(
                      index,
                      "shortFormDeliveryName",
                      e.target.value
                    )
                  }
                  fullWidth
                  margin="normal"
                />
              </div>

              {/* Remove Consignee Button */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <IconButton
                  onClick={() => removeConsignee(index)}
                  color="secondary"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            </div>
          ))}

          {/* Add Consignee Button */}
          <Button
            onClick={addConsignee}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            Add Consignee
          </Button>
        </div>
        <h1>Edit Company Information</h1>
        <div className="form-group">
          <TextField
            label="Company Name"
            value={company.name}
            onChange={(e) => handleCompanyChange("name", e.target.value)}
            fullWidth
          />
          <TextField
            label="Short Form"
            value={company.shortForm}
            onChange={(e) => handleCompanyChange("shortForm", e.target.value)}
            fullWidth
          />
          <TextField
            label="Address"
            value={company.address}
            onChange={(e) => handleCompanyChange("address", e.target.value)}
            fullWidth
          />
          <TextField
            label="City/State"
            value={company.cityState}
            onChange={(e) => handleCompanyChange("cityState", e.target.value)}
            fullWidth
          />
          <TextField
            label="ZIP Code"
            value={company.zip}
            onChange={(e) => handleCompanyChange("zip", e.target.value)}
            fullWidth
          />
          <TextField
            label="Order Number"
            value={company.orderNumber}
            onChange={(e) => handleCompanyChange("orderNumber", e.target.value)}
            fullWidth
          />
          <TextField
            label="Price"
            value={company.price}
            onChange={(e) => handleCompanyChange("price", e.target.value)}
            fullWidth
          />
          <TextField
            label="Currency"
            value={company.currency}
            onChange={(e) => handleCompanyChange("currency", e.target.value)}
            fullWidth
          />
          <TextField
            label="Invoice Number"
            value={invoice.invoice_nr}
            onChange={(e) => setInvoiceNr(e.target.value)}
            fullWidth
          />
          <TextField
            label="Dispatch Email"
            value={company.dispatchEmail}
            onChange={(e) =>
              handleCompanyChange("dispatchEmail", e.target.value)
            }
            fullWidth
          />
          <TextField
            label="Accounting Email"
            value={company.accountingEmail}
            onChange={(e) =>
              handleCompanyChange("accountingEmail", e.target.value)
            }
            fullWidth
          />
          <TextField
            label="Company Phone"
            value={company.phone}
            onChange={(e) => handleCompanyChange("phone", e.target.value)}
            fullWidth
          />
        </div>
        <div className="form-group">
          <h2>Adjust Charges</h2>
          {charges.map((charge, index) => (
            <div key={charge.id} style={{ marginBottom: "20px" }}>
              <TextField
                label="Reason"
                value={charge.reason}
                onChange={(e) =>
                  handleChargeChange(index, "reason", e.target.value)
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Charge Type"
                select
                SelectProps={{ native: true }}
                value={charge.operation || "add"} // Default to "add"
                onChange={(e) =>
                  handleChargeChange(index, "operation", e.target.value)
                }
                fullWidth
                margin="normal"
              >
                <option value="add">Addition</option>
                <option value="subtract">Deduction</option>
              </TextField>
              <TextField
                label="Amount/Percentage"
                value={charge.value}
                type="number"
                onChange={(e) =>
                  handleChargeChange(index, "value", parseFloat(e.target.value))
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Type"
                select
                SelectProps={{ native: true }}
                value={charge.type}
                onChange={(e) =>
                  handleChargeChange(index, "type", e.target.value)
                }
                fullWidth
                margin="normal"
              >
                <option value="percentage">Percentage</option>
                <option value="amount">Flat Amount</option>
              </TextField>

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "10px",
                }}
              >
                <IconButton
                  onClick={() => removeCharge(index)}
                  color="secondary"
                >
                  <RemoveCircleOutline />
                </IconButton>
              </div>
            </div>
          ))}

          {/* Add Charge Button */}
          <Button
            onClick={addCharge}
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutline />}
            fullWidth
            style={{ marginTop: "20px" }}
          >
            Add Charge
          </Button>
        </div>

        {/* Display the Final Balance */}
        <div className="form-group">
          <h2>Final Balance Due</h2>
          <TextField
            label="Final Balance"
            value={`$${parseFloat(finalBalance).toFixed(2)}`}
            InputProps={{
              readOnly: true,
            }}
            fullWidth
            margin="normal"
          />
        </div>
      </Dialog>
      <Modal
        isOpen={isPdfEditorOpen}
        onRequestClose={togglePdfEditorModal}
        fullWidth={true}
        maxWidth={false}
        classes={{ paper: "dialogPaper" }}
      >
        <PdfEditor
          combinedPdfBlob={combinedPdfBlob}
          onSave={(updatedBlob) => {
            setCombinedPdfBlob(updatedBlob); // Update the combined PDF
            togglePdfEditorModal(); // Close the editor modal
          }}
        />
      </Modal>
    </div>
  );
}

export default Pod;
