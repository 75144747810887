import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  MsalProvider,
  useMsal,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { EventType, InteractionStatus } from "@azure/msal-browser";
import { loginRequest, b2cPolicies } from "./authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import Home from "./Home";
import Paps from "./Paps";
import BrokerList from "./BrokerList";
import EditPod from "./EditPod";
import Pars from "./Pars";
import Edit from "./Edit";
import Chat from "./Chat";
import PodHistory from "./PodHistory";
import Pod from "./Pod";
import "./App.css";
import { Navigate } from "react-router-dom";
import Navbar from "./Navbar";
import PdfEditor from "./PdfEditor";

const AppProvider = ({ children }) => {
  const { instance, inProgress } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userId, setUserId] = useState(null);
  const [userCompanyName, setUserCompanyName] = useState(null);
  const [parsStart, setParsStart] = useState(0);
  const [papsStart, setPapsStart] = useState(0);
  const [userCustomsEmail, setUserCustomsEmail] = useState(null);

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    setIsAuthenticated(accounts.length > 0);
    if (accounts && accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
      const account = instance.getActiveAccount();
      if (account && account.idToken) {
        const idTokenClaims = jwtDecode(account.idToken);
        setUserId(idTokenClaims.sub);
      }
    }

    const callbackId = instance.addEventCallback((event) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        instance.setActiveAccount(event.payload.account);
        setIsAuthenticated(true);
        const idTokenClaims = jwtDecode(event.payload.account.idToken);
        setUserId(idTokenClaims.sub);
        setUserCompanyName(idTokenClaims.extension_CompanyName);
        setParsStart(idTokenClaims.extension_PARSCarrierCode);
        setPapsStart(idTokenClaims.extension_PAPSCarrierCode);
        setUserCustomsEmail(idTokenClaims.extension_CustomsEmail);
        if (
          userId &&
          userId !== "undefined" &&
          userId !== null &&
          userId !== ""
        ) {
          sessionStorage.setItem("storageUserId", userId);
          sessionStorage.setItem("storageCompanyName", userCompanyName);
          sessionStorage.setItem("storageParsStart", parsStart);
          sessionStorage.setItem("storagePapsStart", papsStart);
          sessionStorage.setItem("storageCustomsEmail", userCustomsEmail);
          insertCompanyInfo(
            userId,
            userCompanyName,
            parsStart,
            papsStart,
            userCustomsEmail
          );
          sendUserEmailToBackend(userCustomsEmail);
        }
      } else if (event.eventType === EventType.LOGOUT_SUCCESS) {
        setIsAuthenticated(false);
        setUserId(null);
      }
    });

    return () => instance.removeEventCallback(callbackId);
  }, [
    instance,
    userId,
    userCompanyName,
    papsStart,
    parsStart,
    userCustomsEmail,
  ]);

  useEffect(() => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      instance.loginRedirect(loginRequest).catch(console.error);
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    if (!userId && instance) {
      instance
        .acquireTokenSilent({
          scopes: ["user.read"],
          account: instance.getActiveAccount(),
        })
        .then((response) => {
          const idTokenClaims = jwtDecode(response.idToken);
          setUserId(idTokenClaims.sub);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginRedirect({ scopes: ["user.read"] });
          }
        });
    }
  }, [instance, userId]);

  const insertCompanyInfo = async (
    userId,
    userCompanyName,
    parsStart,
    papsStart,
    userCustomsEmail
  ) => {
    const endpoint = "/api/insert-company-info";
    try {
      const response = await fetch(endpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userId,
          companyName: userCompanyName,
          parsCode: parsStart,
          papsCode: papsStart,
          customsEmail: userCustomsEmail,
        }),
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.error("Error inserting company info:", error);
    }
  };

  function sendUserEmailToBackend(userEmail) {
    const url = "/api/store-user-email";

    const data = {
      userEmail: userEmail,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => console.log("Success:", data))
      .catch((error) => console.error("Error:", error));
  }

  return (
    <>
      <AuthenticatedTemplate>
        {React.cloneElement(children, { userId })}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <div>Authenticating...</div>
      </UnauthenticatedTemplate>
    </>
  );
};

function App({ instance }) {
  const { inProgress } = useMsal();
  return (
    <MsalProvider instance={instance}>
      <AppProvider>
        <Router>
          <Navbar
            b2cPolicies={b2cPolicies}
            instance={instance}
            inProgress={inProgress}
          />
          <Routes>
            <Route path="/" element={<Navigate replace to="/home" />} />
            <Route path="/home" element={<Home />} />
            <Route path="/broker-list" element={<BrokerList />} />
            <Route path="/paps" element={<Paps />} />
            <Route path="/pars" element={<Pars />} />
            <Route path="/edit" element={<Edit />} />
            <Route path="/edit-pod" element={<EditPod />} />
            <Route path="/pod" element={<Pod />} />
            <Route path="/pod-history" element={<PodHistory />} />
            <Route path="/edit-pdf" element={<PdfEditor/>} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </Router>
      </AppProvider>
    </MsalProvider>
  );
}

export default App;
