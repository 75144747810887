import React, { useRef, useState, useEffect } from "react";
import { PDFDocument } from "pdf-lib";
import * as pdfjs from "pdfjs-dist";
import { saveAs } from "file-saver";
import "./PdfEditor.css";

pdfjs.GlobalWorkerOptions.workerSrc =
  "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.14.305/pdf.worker.js";

function PdfEditor({ combinedPdfBlob, onSave }) {
  const canvasRef = useRef(null);
  const [text, setText] = useState("");
  const [pdfDoc, setPdfDoc] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [mouseDown, setMouseDown] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [drawingColor, setDrawingColor] = useState("#FF0000");
  const [strokeWidth, setStrokeWidth] = useState(2);
  const [pdfBuffer, setPdfBuffer] = useState(null);
  const [modifiedPdfBytes, setModifiedPdfBytes] = useState(null);
  const [drawMode, setDrawMode] = useState("draw");
  const [canvasData, setCanvasData] = useState({});
  const [history, setHistory] = useState({}); // History stack for undo

  useEffect(() => {
    const loadPdf = async () => {
      if (combinedPdfBlob) {
        const buffer = await combinedPdfBlob.arrayBuffer();
        const pdfjsDoc = pdfjs.getDocument({ data: buffer });
        const pdfLibDoc = await PDFDocument.load(buffer);
        const pdf = await pdfjsDoc.promise;
        setTotalPages(pdf.numPages);
        setPdfDoc(pdf);
        setPdfBuffer(pdfLibDoc);
        setCurrentPage(1);
        setCanvasData({});
        setHistory({}); // Reset history when new PDF is loaded
      }
    };

    if (combinedPdfBlob) {
      loadPdf();
    }
  }, [combinedPdfBlob]);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const buffer = await file.arrayBuffer();
      const pdfjsDoc = pdfjs.getDocument({ data: buffer });
      const pdfLibDoc = await PDFDocument.load(buffer);
      const pdf = await pdfjsDoc.promise;
      setTotalPages(pdf.numPages);
      setPdfDoc(pdf);
      setPdfBuffer(pdfLibDoc);
      setCurrentPage(1);
      setCanvasData({});
      setHistory({}); // Reset history when new PDF is loaded
    }
  };

  const getMousePosition = (canvas, event) => {
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;
    return { x, y };
  };

  const startDrawing = (e) => {
    const position = getMousePosition(canvasRef.current, e);
    setMousePosition(position);
    setMouseDown(true);
    if (drawMode === "text") {
      drawText(position.x, position.y);
      saveCanvasData();
      setMouseDown(false);
    }
  };

  const draw = (e) => {
    if (!mouseDown) return;
    const newPosition = getMousePosition(canvasRef.current, e);
    const ctx = canvasRef.current.getContext("2d");
    ctx.beginPath();
    ctx.moveTo(mousePosition.x, mousePosition.y);
    ctx.lineTo(newPosition.x, newPosition.y);
    ctx.strokeStyle = drawingColor;
    ctx.lineWidth = strokeWidth;
    ctx.stroke();
    setMousePosition(newPosition);
  };

  const drawText = (x, y) => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.font = `${strokeWidth * 10}px Helvetica`;
    ctx.fillStyle = drawingColor;
    text.split("\n").forEach((line, i) => {
      ctx.fillText(line, x, y + i * strokeWidth * 10);
    });
  };

  const handleMouseMove = (e) => {
    if (drawMode === "draw") {
      draw(e);
    }
  };

  const handleMouseUp = () => {
    setMouseDown(false);
    if (drawMode === "draw") {
      saveCanvasData();
    }
  };

  const saveCanvasData = () => {
    const dataUrl = canvasRef.current.toDataURL("image/png");
    setCanvasData((prevData) => ({
      ...prevData,
      [currentPage]: dataUrl,
    }));

    // Update history for undo
    setHistory((prevHistory) => ({
      ...prevHistory,
      [currentPage]: [...(prevHistory[currentPage] || []), dataUrl], // Append new state
    }));
  };

  const modifyPdf = async () => {
    if (!pdfBuffer || !canvasRef.current) return;
    const modifiedPdfDoc = await PDFDocument.create();
    for (let i = 1; i <= pdfBuffer.getPageCount(); i++) {
      const [copiedPage] = await modifiedPdfDoc.copyPages(pdfBuffer, [i - 1]);
      modifiedPdfDoc.addPage(copiedPage);
      if (canvasData[i]) {
        const canvasImage = await modifiedPdfDoc.embedPng(canvasData[i]);
        const { width, height } = copiedPage.getSize();
        copiedPage.drawImage(canvasImage, { x: 0, y: 0, width, height });
      }
    }
    const pdfBytes = await modifiedPdfDoc.save();
    const updatedBlob = new Blob([pdfBytes], { type: "application/pdf" });
    if (onSave) {
      onSave(updatedBlob);
    } else {
      setModifiedPdfBytes(updatedBlob);
    }
  };

  const downloadModifiedPdf = () => {
    if (!modifiedPdfBytes) return;
    saveAs(
      new Blob([modifiedPdfBytes], { type: "application/pdf" }),
      "modified-document.pdf"
    );
  };

  const undoLastAction = () => {
    if (!history[currentPage] || history[currentPage].length === 0) return;

    // Remove the last state from the history for the current page
    const newHistory = history[currentPage].slice(0, -1);

    // Update canvasData with the new state
    setCanvasData((prevData) => ({
      ...prevData,
      [currentPage]:
        newHistory.length > 0 ? newHistory[newHistory.length - 1] : null,
    }));

    // Update the history state
    setHistory((prevHistory) => ({
      ...prevHistory,
      [currentPage]: newHistory,
    }));
  };

  useEffect(() => {
    let renderTask = null;

    const renderPdfPage = async (pdf, pageNumber) => {
      if (renderTask) {
        renderTask.cancel();
      }

      const page = await pdf.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 2.5 });
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      renderTask = page.render(renderContext);
      try {
        await renderTask.promise;
      } catch (error) {
        console.error("Error rendering PDF page:", error);
      } finally {
        renderTask = null;
      }
    };

    const currentCanvasData = canvasData[currentPage];

    if (pdfDoc && !currentCanvasData) {
      renderPdfPage(pdfDoc, currentPage);
    } else if (currentCanvasData) {
      const canvas = canvasRef.current;
      if (!canvas) return;

      const context = canvas.getContext("2d");
      const img = new Image();
      img.src = currentCanvasData;
      img.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before rendering
        context.drawImage(img, 0, 0);
      };
    }

    return () => {
      if (renderTask) {
        renderTask.cancel();
      }
    };
  }, [currentPage, pdfDoc, canvasData]);

  return (
    <div className="App">
      <header className="App-header">
        {!combinedPdfBlob && (
          <input
            type="file"
            onChange={handleFileUpload}
            accept="application/pdf"
          />
        )}
        {pdfDoc && (
          <div>
            <button onClick={modifyPdf}>Save Changes</button>
            {!onSave && (
              <button onClick={downloadModifiedPdf}>
                Download Modified PDF
              </button>
            )}
            <button onClick={() => setCurrentPage((v) => Math.max(v - 1, 1))}>
              Previous Page
            </button>
            <button
              onClick={() => setCurrentPage((v) => Math.min(v + 1, totalPages))}
            >
              Next Page
            </button>
            <button onClick={undoLastAction}>Undo</button> {/* Undo Button */}
          </div>
        )}
        <div className="flex justify-center items-center space-x-4 my-4">
          <button
            onClick={() => setDrawMode("text")}
            className="px-4 py-2 rounded bg-gray-300"
          >
            Text Mode
          </button>
          <button
            onClick={() => setDrawMode("draw")}
            className="px-4 py-2 rounded bg-blue-500 text-white"
          >
            Draw Mode
          </button>
        </div>
        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          style={{ border: "1px solid black" }}
        ></canvas>
        <div className="flex justify-center items-center space-x-4 my-4">
          <input
            type="color"
            value={drawingColor}
            onChange={(e) => setDrawingColor(e.target.value)}
          />
          <div className="flex items-center">
            <label className="mr-2">Stroke Width:</label>
            <input
              type="number"
              value={strokeWidth}
              onChange={(e) => setStrokeWidth(Number(e.target.value))}
              min="1"
              max="10"
              className="border rounded w-16 text-center"
            />
          </div>
        </div>
        <div>
          <textarea
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Enter text here"
            className="p-2 border rounded w-full"
          />
        </div>
      </header>
    </div>
  );
}

export default PdfEditor;
